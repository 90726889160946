import * as React from "react"
import {useState} from "react"
import {ButtonPrimary, ButtonSuccess} from "../components/button";

const asterisk = () => {
    return (<span className={"text-2xl text-red-800"}>*</span>)
}

function handleSubmission(event) {
    event.preventDefault()

    let form = document.getElementById('contact-form');
    // @ts-ignore
    let formData = new FormData(form);

    fetch('/', {
        method: 'POST',
        headers: {"Content-Type": "application/x-www-form-urlencoded"},
        // @ts-ignore
        body: new URLSearchParams(formData).toString()
    }).then(() => console.log('Form successfully submitted')).catch((error) =>
        alert(error))
}

export default function ContactForm() {
    const [submitted, setSubmitted] = useState(false);

    return (
        <>
            {!submitted ? (
                <div className={"mb-4"}>
                    Fields marked with an {asterisk()} are required
                </div>
            ) : null}

            <div className={"mt-8 max-w-lg"}>
                {!submitted ? (
                    <div className={"grid grid-cols-1 gap-6"}>
                        <form id={'contact-form'} name={'contact-form'} method={"post"}
                              onSubmit={(event) => {
                                  handleSubmission(event);
                                  setSubmitted(true)
                              }}
                              data-netlify={"true"}>
                            <input type="hidden" name="form-name" value="contact-form"/>
                            <div className="block mb-4">
                                <label className="text-gray-700">Full name{asterisk()}
                                    <input id={'name'}
                                           name={"name"}
                                           type="text"
                                           autoComplete={"name"}
                                           className="mt-1 block w-full rounded shadow-sm focus:border-blue-50 focus:ring focus:ring-blue-200"
                                           required/></label>
                            </div>
                            <div className="block mb-4">
                                <label className="text-gray-700">Email{asterisk()}
                                    <input name={'email'} type="email"
                                           autoComplete={'email'}
                                           className="mt-1 block w-full rounded shadow-sm focus:border-blue-50 focus:ring focus:ring-blue-200"
                                           required/></label>
                            </div>
                            <div className="block mb-4">
                                <label className="text-gray-700">Phone
                                    <input id={'phone'} name={'phone'} type="tel"
                                           autoComplete={"tel"}
                                           className="mt-1 block w-full rounded shadow-sm focus:border-blue-50 focus:ring focus:ring-blue-200"/></label>
                            </div>
                            <div className="block mb-4">
                                <label id={'details'} className="text-gray-700">Additional details{asterisk()}
                                    <textarea name={'details'}
                                              className="mt-1 block w-full rounded shadow-sm focus:border-blue-50 focus:ring focus:ring-blue-200"
                                              rows={5}
                                              required/></label>
                            </div>
                            <div className={'block'}>
                                <ButtonPrimary attrs={{type: "submit"}}>Submit</ButtonPrimary>
                            </div>
                        </form>
                    </div>

                ) : (
                    <div
                        className={"justify-center w-full border-t-4 rounded border-emerald-700 shadow-md hover:shadow-lg transition duration-200 ease-linear border-1 h-52 px-3 py-2 flex flex-col"}>
                        <div className={"font-bold text-xl mb-4 py-2"}>
                            Form submitted!
                        </div>
                        <div className={"text-gray-900 grow text-gray-800"}>
                            We have received your submission. We will be in contact with you as soon as possible.

                        </div>
                        <div className={"py-4"}>
                            <ButtonSuccess attrs={{href: "/"}}>Go back</ButtonSuccess>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
