import * as React from "react"
import {PageProps} from "gatsby"
import {Accent, Layout} from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../forms/contact"
import Section from "../components/section";
import {StaticImage} from "gatsby-plugin-image";
import {FaRegQuestionCircle} from "react-icons/fa";

export default function IndexRoute(props: PageProps) {
    return (
        <>
            <Layout>
                <Seo title="Get in touch "/>
                <Section padding={'pb-12'}>
                    <h1 className={'text-2xl font-semibold text-gray-800 mb-4 w-fit'}>Get in touch {Accent()}</h1>
                    <div className={'md:grid grid-cols-2 gap-x-20'}>
                        <div>
                            <ContactForm/>
                        </div>
                        <div className={'hidden md:block flex relative'}>
                            <StaticImage
                                alt={"Van"}
                                draggable={false}
                                imgClassName={"mx-auto rounded-lg shadow-md mx-auto"}
                                src={"../images/man_ladder.jpg"}
                            />
                            <div className={'absolute bottom-0 right-0 p-4 z-20'}>
                                <FaRegQuestionCircle title={"Credit: Craig Whitehead @sixstreetunder on Unsplash"}
                                                     className={'text-white text-lg font-bold hover:cursor-pointer'}/>
                            </div>

                        </div>
                    </div>

                </Section>

            </Layout>
        </>
    );

}