import * as React from "react"
import {Link} from "gatsby";

interface ButtonProps {
    readonly attrs?: object
    readonly children: any
    classes?: string[]
    readonly buttonType?: ButtonType
}

enum ButtonType {
    primary,
    secondary
}


const buttonColours = {
    primary: ['bg-red-800', 'hover:bg-red-900', 'text-white'],
    secondary: ['bg-indigo-100', 'hover:bg-indigo-200', 'text-indigo-700'],
    success: ['bg-emerald-700', 'hover:bg-emerald-800', 'text-white']
}


const ButtonPrimary = (props: ButtonProps) => {
    let p = {...props}
    if (!('classes' in p)) {
        p.classes = []
    }
    p.classes.push(...buttonColours.primary)
    return Button(p);
}

const ButtonSecondary = (props: ButtonProps) => {
    let p = {...props}
    if (!('classes' in p)) {
        p.classes = []
    }
    p.classes.push(...buttonColours.secondary)
    return Button(p)
}

const ButtonSuccess = (props: ButtonProps) => {
    let p = {...props}
    if (!('classes' in p)) {
        p.classes = []
    }
    p.classes.push(...buttonColours.success)
    return Button(p)
}

const Button = (props: ButtonProps) => {
    const baseClasses = [
        "px-4",
        "py-2",
        "transition-colors",
        "duration-300",
        "transform",
        "rounded",
        "hover:cursor-pointer",
        'text-bold'
    ]

    let {attrs, children, classes, buttonType} = props;
    let classesStr = baseClasses.join(" ")
    if (typeof classes !== "undefined") {
        classesStr += " " + classes.join(" ")
    }
    if (!attrs) {
        attrs = {}
    }

    const isLink = (attrs) => {
        return "href" in attrs;
    }

    if (isLink(attrs)) {
        if (attrs['href'].includes('http')) {
            return (
                <a className={classesStr} {...attrs}>
                    {children}
                </a>
            )
        } else {
            return (
                <Link data-internal={true} className={classesStr} to={attrs['href']}>{children}</Link>
            )
        }
    }

    return (
        <button className={classesStr} {...attrs}>
            {children}
        </button>
    )
}

// export default Button;
export {Button, ButtonPrimary, ButtonSecondary, ButtonSuccess};
